<template>
  <section class="content">
    <div class="container-fluid">

      <div class="card card-primary card-outline direct-chat direct-chat-primary">
        <div class="card-header">
          <h3 class="card-title">Direct Chat</h3>
          <div class="card-tools">
            <span title="3 New Messages" class="badge bg-primary">3</span>
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool" title="Contacts" data-widget="chat-pane-toggle">
              <i class="fas fa-comments"></i>
            </button>
            <button type="button" class="btn btn-tool" data-card-widget="remove">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="card-body">

          <div class="direct-chat-messages">
            <div class="direct-chat-msg " :class="item.dir" v-for="(item, index) in messages" :key="index">
              <img class="direct-chat-img" src="@/assets/img/avatar.png">
              <div class="direct-chat-text">
                {{ item.message }}
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="input-group">
            <input type="text" v-model="newMessage" @keyup.enter="sendMessage" name="message"
              placeholder="Type Message ..." class="form-control" />
            <span class="input-group-append">
              <button @click="sendMessage" class="btn btn-primary">Send</button>
              <button @click="openSocket" class="btn btn-primary">連線</button>
              <button @click="clear" class="btn btn-primary">清除</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SocketChat',
  data() {
    return {
      socket: null,
      messages: [],
      newMessage: []
    };
  },
  mounted: function () {
    this.openSocket();
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.socket.send(this.newMessage);
        this.newMessage = '';
      }
    },
    openSocket() {
      this.socket = new WebSocket('ws://win.job72.cc:5957/Laputa');

      let arr = this.messages;
      this.socket.onopen = function () {
        // arr.push("Connected to WebSocket server.");
      };
      this.socket.onmessage = function (event) {

        const now = new Date();
        const dt = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        const info = event.data;
        let msn = {
          dir: '',
          message: `[${dt}]${info}`
        };
        arr.push(msn);
      };
      this.socket.onclose = function () {

        const now = new Date();
        const dt = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        let msn = {
          dir: 'right',
          message: `[${dt}]Disconnected from WebSocket server.`
        };
        arr.push(msn);
      };
    },
    clear() {
      this.messages = [];
    },
    gettalk(info, r = '') {
      let msn = {
        dir: r,
        message: `[${this.formattedTime()}]${info}`
      };
      return msn;
    },
    formattedTime() {
      const now = new Date();
      return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }
  }
};
</script>